<script setup lang="ts">
export interface Tag {
  id: string
  name: string
}

const props = withDefaults(
  defineProps<{
    id?: string
    name: string
    active?: boolean
  }>(),
  {
    id: 'none',
    active: false,
  },
)

const active = computed(() => props.active)
</script>

<template>
  <ButtonBase :class="['tag', 'label', { active }]">
    <template #before>
      <slot name="icon" />
    </template>
    {{ props.name }}
  </ButtonBase>
</template>
<style scoped lang="scss">
.tag {
  @include fluid-ts-ds(--pv, 12px, 19px);
  @include fluid-ts-ds(--ph, 16px, 24px);

  --color-background: var(--c-background-medium);
  &:not(.active) {
    --color-background-hover: var(--c-secondary);
  }

  :deep(.background) {
    border: none;
    transform: none !important;
    @include fluid-ts-ds(border-radius, 6px, 8px);
  }

  :deep(svg) {
    --icon-size: 24px;
    margin-right: 8px;
  }

  &.active {
    --color: var(--c-white);
    --color-hover: var(--c-white);
    --color-background: var(--c-primary);
    --color-background-hover: var(--c-blue-rollover);
  }
}
</style>
